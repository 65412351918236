<template>
  <div>
    <router-view/>
    <vue-snotify/>
    <Loading/>
    <Notification/>
  </div>
</template>

<script>
import Vue from 'vue';
import {post} from '@/services/api';
import {getDataStorage} from '@/helpers/localStorage';

export default Vue.extend({
  name: 'dashboard',
  components: {
    Loading: () => import('../components/Loading'),
    Notification: () => import('../components/Notification'),
  },
  methods: {
    showMessage() {
      this.$swal({
        customClass: 'swall-left-title',
        html: '<h6 class="swal2-title sub-title">Checking for Updates.</h6>',
        didOpen: () => {
          this.$swal.showLoading()
        },
        background: '#ffffff',
        allowOutsideClick: false,
        footer: "<h6 class='swal2-title sub-title'>Please wait...</h6>"
      });
    }
  },
  async mounted() {
    const {data} = await post('auth/verify-token', {token: getDataStorage('admin-token')});
    if (data) {
      this.$socket.on('connect', async () => {
        this.$swal.close();
      });
      this.$socket.on('disconnect', (reason) => {
        if (reason !== 'io client disconnect') {
          this.showMessage();
        }
      });
      const id = getDataStorage('admin-id');
      if (id) {
        this.$store.dispatch('loadUser')
        this.$socket.io.opts.query = {
          token: getDataStorage('admin-token')
        };
        setTimeout(() => {
          if (!this.$socket.connected) {
            this.showMessage()
          }
        }, 2500)
        this.$socket.open();
      }
      this.sockets.subscribe('closeSession', () => {
        localStorage.clear();
        this.$router.push('/');
      });
    } else {
      localStorage.clear();
      this.$router.push('/');
    }
  }
});
</script>
<style lang="scss">
.swall-left-title {
  .swal2-title {
    justify-content: flex-start;
    width: 100%;
    font-size: 1.2em;

    h6 {
      padding: 0;
      margin: 0;
    }
  }

  &.swal2-popup {
    padding: 0.45em;
    width: 20em;
  }

  .swal2-actions {
    margin: 0.25em auto 0;
  }

  .swal2-footer {
    border: none;
    margin: 0.25em auto 0;

    h6 {
      font-size: 1.2em;
      margin: 0;
    }
  }
}
</style>
